import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vsm-menu',{staticClass:"mb-10",attrs:{"menu":_vm.menu,"base-width":380,"base-height":400,"screen-offset":10,"element":"header","handler":"hover"},on:{"open-dropdown":_vm.onOpenDropdown,"close-dropdown":_vm.onCloseDropdown},scopedSlots:_vm._u([{key:"before-nav",fn:function(){return [_c('li',{staticClass:"vsm-section logo-section"},[_c('img',{attrs:{"src":"https://vuejs.org/images/logo.png","alt":"My Logo"}})])]},proxy:true},{key:"default",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"wrap-content"},[_c('div',{staticClass:"wrap-content__block"},[_vm._v("Header: "+_vm._s(item.title))]),_c('div',{staticClass:"wrap-content__item"},[_c(VList,[_c(VListItemGroup,{attrs:{"color":"primary"}},_vm._l((item.content),function(nItem,i){return _c(VListItem,{key:i,attrs:{"to":nItem.link}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-play")])],1),_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(nItem.title)}})],1)],1)}),1)],1)],1)])]}},{key:"after-nav",fn:function(){return [_c('li',{staticClass:"vsm-section vsm-mob-hide"},[_c('button',[_vm._v("My Button")])])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }